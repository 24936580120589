import React, { useRef, useState } from "react";
import { Editor } from '@monaco-editor/react';


const EditorComponent = ({ runHandler, validateHandler, isSandbox, cachedContent }) => {
    const initialContent = cachedContent || "SELECT *\nFROM test\n--write your query";
    const [content, setContent] = useState(initialContent);
    const monacoRef = useRef(null);
    const editorRef = useRef(null);

    function handleEditorWillMount(monaco) {
        monaco.languages.register({ id: 'pgsql' });

        monaco.languages.setMonarchTokensProvider('pgsql', {
            defaultToken: '',
            ignoreCase: true, // SQL is case-insensitive

            tokenizer: {
                root: [
                    // Keywords
                    [/\b(SELECT|INSERT|UPDATE|DELETE|MERGE|UPSERT)\b/, 'keyword.dml'],
                    [/\b(FROM|WHERE|GROUP BY|ORDER BY|HAVING|LIMIT|OFFSET)\b/, 'keyword.clause'],
                    [/\b(JOIN|INNER JOIN|LEFT JOIN|RIGHT JOIN|FULL JOIN|CROSS JOIN|NATURAL JOIN)\b/, 'keyword.join'],
                    [/\b(ON|AS|USING|VALUES|INTO|SET)\b/, 'keyword'],
                    [/\b(AND|OR|NOT|IS|NULL|TRUE|FALSE|UNKNOWN)\b/, 'keyword.operator'],
                    [/\b(CREATE|ALTER|DROP|TRUNCATE|VACUUM|GRANT|REVOKE)\b/, 'keyword.ddl'],
                    [/\b(TABLE|VIEW|INDEX|SEQUENCE|TRIGGER|SCHEMA|DATABASE|FUNCTION|PROCEDURE)\b/, 'keyword.object'],
                    [/\b(PRIMARY KEY|FOREIGN KEY|CONSTRAINT|UNIQUE|CHECK|DEFAULT|REFERENCES)\b/, 'keyword.constraint'],
                    [/\b(BEGIN|COMMIT|ROLLBACK|SAVEPOINT|TRANSACTION)\b/, 'keyword.transaction'],
                    [/\b(WITH|RECURSIVE|EXISTS|CASE|WHEN|THEN|ELSE|END|UNION|ALL|INTERSECT|EXCEPT)\b/, 'keyword.control'],

                    // Data Types
                    [/\b(INT|INTEGER|BIGINT|SMALLINT|DECIMAL|NUMERIC|REAL|DOUBLE PRECISION|SERIAL)\b/, 'type.numeric'],
                    [/\b(VARCHAR|CHAR|TEXT|BYTEA|UUID)\b/, 'type.string'],
                    [/\b(BOOLEAN|BOOL)\b/, 'type.boolean'],
                    [/\b(DATE|TIME|TIMESTAMP|INTERVAL|TIMESTAMPTZ)\b/, 'type.datetime'],
                    [/\b(JSON|JSONB|XML|ARRAY|ENUM)\b/, 'type.special'],

                    // Functions
                    [/\b(COUNT|SUM|AVG|MIN|MAX|FIRST|LAST)\b/, 'function.aggregate'],
                    [/\b(COALESCE|NULLIF|NVL|GREATEST|LEAST)\b/, 'function.conditional'],
                    [/\b(TO_CHAR|TO_DATE|TO_TIMESTAMP|TO_NUMBER)\b/, 'function.conversion'],
                    [/\b(UPPER|LOWER|INITCAP|CONCAT|SUBSTRING|TRIM|LENGTH)\b/, 'function.string'],
                    [/\b(NOW|CURRENT_TIMESTAMP|CURRENT_DATE|EXTRACT|DATE_TRUNC)\b/, 'function.datetime'],

                    // Operators
                    [/[=<>!]+/, 'operator'],
                    [/[\+\-\*\/\%]/, 'operator'],
                    [/\|\|/, 'operator'], // string concatenation
                    [/::/, 'operator'], // type cast

                    // Numbers
                    [/\b\d+\b/, 'number'],
                    [/\b\d+\.\d*([eE][\-+]?\d+)?\b/, 'number.float'],
                    [/\b0x[\da-fA-F]+\b/, 'number.hex'],

                    // Identifiers
                    [/[a-zA-Z_]\w*/, 'identifier'],
                    [/\"[^\"]*\"/, 'identifier.quoted'],

                    // Punctuation
                    [/[;,.]/, 'delimiter'],
                    [/[\(\)]/, 'delimiter.parenthesis'],

                    // Strings
                    [/'/, { token: 'string', next: '@string' }],
                    [/\$\$/, { token: 'string.delimiter', next: '@dollarString' }],

                    // Comments
                    { include: '@whitespace' },
                    { include: '@comments' },
                ],

                string: [
                    [/[^']+/, 'string'],
                    [/''/, 'string'],
                    [/'/, { token: 'string', next: '@pop' }]
                ],

                dollarString: [
                    [/[^\$]+/, 'string'],
                    [/\$\$/, { token: 'string.delimiter', next: '@pop' }]
                ],

                whitespace: [
                    [/[ \t\r\n]+/, 'white']
                ],

                comments: [
                    [/--.*$/, 'comment'],
                    [/\/\*/, { token: 'comment.quote', next: '@comment' }]
                ],

                comment: [
                    [/[^/*]+/, 'comment'],
                    [/\*\//, { token: 'comment.quote', next: '@pop' }],
                    [/./, 'comment']
                ]
            }
        });

        monaco.languages.registerCompletionItemProvider('pgsql', {
            provideCompletionItems: () => {
                const completions = {
                    // DML Keywords
                    dmlKeywords: [
                        {
                            label: 'SELECT',
                            insertText: 'SELECT ${1:columns}\nFROM ${2:table}',
                            detail: 'Retrieve data from a table',
                            documentation: {
                                value: 'Example:\nSELECT column1, column2\nFROM table_name\nWHERE condition;'
                            }
                        },
                        {
                            label: 'INSERT INTO',
                            insertText: 'INSERT INTO ${1:table} (${2:columns})\nVALUES (${3:values})',
                            detail: 'Insert new data into a table',
                            documentation: {
                                value: 'Example:\nINSERT INTO table_name (column1, column2)\nVALUES (value1, value2);'
                            }
                        },
                        {
                            label: 'UPDATE',
                            insertText: 'UPDATE ${1:table}\nSET ${2:column} = ${3:value}\nWHERE ${4:condition}',
                            detail: 'Update existing data in a table',
                            documentation: {
                                value: 'Example:\nUPDATE table_name\nSET column1 = value1\nWHERE condition;'
                            }
                        },
                        {
                            label: 'DELETE',
                            insertText: 'DELETE FROM ${1:table}\nWHERE ${2:condition}',
                            detail: 'Delete data from a table',
                            documentation: {
                                value: 'Example:\nDELETE FROM table_name\nWHERE condition;'
                            }
                        },
                    ],

                    // Clauses
                    clauseKeywords: [
                        {
                            label: 'WHERE',
                            insertText: 'WHERE ${1:condition}',
                            detail: 'Filter data based on conditions',
                            documentation: {
                                value: 'Examples:\nWHERE column1 = value\nWHERE column1 IN (value1, value2)\nWHERE column1 BETWEEN value1 AND value2'
                            }
                        },
                        {
                            label: 'ORDER BY',
                            insertText: 'ORDER BY ${1:column} ${2:ASC|DESC}',
                            detail: 'Sort the result set',
                            documentation: {
                                value: 'Example:\nORDER BY column1 ASC, column2 DESC'
                            }
                        },
                        {
                            label: 'GROUP BY',
                            insertText: 'GROUP BY ${1:columns}',
                            detail: 'Group rows having the same values',
                            documentation: {
                                value: 'Example:\nGROUP BY column1, column2\nHAVING COUNT(*) > 1'
                            }
                        },
                        {
                            label: 'HAVING',
                            insertText: 'HAVING ${1:condition}',
                            detail: 'Filter groups based on a condition',
                            documentation: {
                                value: 'Example:\nHAVING COUNT(*) > 1'
                            }
                        },
                    ],

                    // Joins
                    joinKeywords: [
                        {
                            label: 'INNER JOIN',
                            insertText: 'INNER JOIN ${1:table}\nON ${2:condition}',
                            detail: 'Join tables where there are matching values',
                            documentation: {
                                value: 'Example:\nINNER JOIN table2\nON table1.id = table2.id'
                            }
                        },
                        {
                            label: 'LEFT JOIN',
                            insertText: 'LEFT JOIN ${1:table}\nON ${2:condition}',
                            detail: 'Return all records from left table and matching from right',
                            documentation: {
                                value: 'Example:\nLEFT JOIN table2\nON table1.id = table2.id'
                            }
                        },
                    ],

                    // Functions
                    aggregateFunctions: [
                        {
                            label: 'COUNT',
                            insertText: 'COUNT(${1:*})',
                            detail: 'Count the number of rows',
                            documentation: {
                                value: 'Examples:\nCOUNT(*)\nCOUNT(DISTINCT column)'
                            }
                        },
                        {
                            label: 'SUM',
                            insertText: 'SUM(${1:column})',
                            detail: 'Calculate the sum of values',
                            documentation: {
                                value: 'Example:\nSUM(quantity)'
                            }
                        },
                        {
                            label: 'AVG',
                            insertText: 'AVG(${1:column})',
                            detail: 'Calculate the average value',
                            documentation: {
                                value: 'Example:\nAVG(price)'
                            }
                        },
                    ],

                    // Common Table Expressions (CTE)
                    cteKeywords: [
                        {
                            label: 'WITH',
                            insertText: 'WITH ${1:cte_name} AS (\n\t${2:SELECT statement}\n)',
                            detail: 'Create a Common Table Expression (CTE)',
                            documentation: {
                                value: 'Example:\nWITH cte_name AS (\n\tSELECT column1\n\tFROM table1\n)\nSELECT *\nFROM cte_name;'
                            }
                        },
                    ],

                    // Window Functions
                    windowFunctions: [
                        {
                            label: 'ROW_NUMBER',
                            insertText: 'ROW_NUMBER() OVER (${1:PARTITION BY column ORDER BY column})',
                            detail: 'Assign sequential numbers to rows',
                            documentation: {
                                value: 'Example:\nROW_NUMBER() OVER (PARTITION BY department ORDER BY salary DESC)'
                            }
                        },
                    ],

                    // Data Types
                    dataTypes: [
                        {
                            label: 'INTEGER',
                            insertText: 'INTEGER',
                            detail: '4-byte integer',
                        },
                        {
                            label: 'VARCHAR',
                            insertText: 'VARCHAR(${1:length})',
                            detail: 'Variable-length character string',
                        },
                        {
                            label: 'TIMESTAMP',
                            insertText: 'TIMESTAMP',
                            detail: 'Date and time',
                        },
                    ],

                    // Conditional Expressions
                    conditionalExpressions: [
                        {
                            label: 'CASE',
                            insertText: [
                                'CASE',
                                '\tWHEN ${1:condition} THEN ${2:result}',
                                '\tELSE ${3:result}',
                                'END'
                            ].join('\n'),
                            detail: 'Conditional expression',
                            documentation: {
                                value: 'Example:\nCASE\n\tWHEN amount > 1000 THEN \'High\'\n\tWHEN amount > 500 THEN \'Medium\'\n\tELSE \'Low\'\nEND'
                            }
                        },
                    ],
                };

                // Combine all suggestions
                const suggestions = [
                    ...completions.dmlKeywords,
                    ...completions.clauseKeywords,
                    ...completions.joinKeywords,
                    ...completions.aggregateFunctions,
                    ...completions.cteKeywords,
                    ...completions.windowFunctions,
                    ...completions.dataTypes,
                    ...completions.conditionalExpressions,
                ].map(item => ({
                    ...item,
                    kind: monaco.languages.CompletionItemKind.Keyword,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                }));
                return { suggestions };
            }
        });
    }

    function handleEditorDidMount(editor, monaco) {
        // here is another way to get monaco instance
        // you can also store it in `useRef` for further usage
        monacoRef.current = monaco;
        editorRef.current = editor;
        editor.onKeyDown((e) => {

        if ((e.code === 'Enter' || e.code === 'NumpadEnter') && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                console.log('Ctrl/Cmd + Enter pressed');
                // Your custom logic here
                document.getElementById('run-query').click();
            }
        });
    }


    const handleChange = (value) => {
        setContent(value);
    };

    const options = {
        selectOnLineNumbers: true,
        lineHeight: 21,
        fontSize: 19,
        lineDecorationsWidth: 0,
        selectOnLineNumbers: true,
        roundedSelection: false,
        readOnly: false,
        cursorStyle: 'line',
        automaticLayout: true,
        minimap: {
            enabled: false,
        },
        scrollbar: {
            useShadows: false,
            verticalHasArrows: true,
            horizontalHasArrows: true,
            vertical: "hidden",
            horizontal: "hidden",
            verticalScrollbarSize: 17,
            horizontalScrollbarSize: 17,
            arrowSize: 30,
        }
    }

    return (
        <div className="mt-2">
            <Editor
                height="25vh"
                defaultLanguage="pgsql"
                theme='vs-light' // vs-dark
                value={content}
                onChange={handleChange}
                defaultValue="SELECT *"
                beforeMount={handleEditorWillMount}
                onMount={handleEditorDidMount}
                options={options}
            />

            <div className="bg-white">
                <button id="run-query" className="btn btn-info" style={{ margin: '10px' }} onClick={runHandler} value={content}>Run Query</button>
                {isSandbox ? <span></span> : <button className="btn btn-primary" onClick={validateHandler} value={content}>Submit</button>}
            </div>
        </div>
    );
}

export default EditorComponent;
