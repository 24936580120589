import React, { useEffect, useState } from 'react';
import '../assets/css/cookies.css';

const Cookies = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // Check if consent is already set
        if (localStorage.getItem('consentMode') === null) {
            // Add 5 second delay before showing the banner
            const timer = setTimeout(() => {
                setShowBanner(true);
            }, 10000);

            // Cleanup timeout if component unmounts
            return () => clearTimeout(timer);
        }
    }, []);

    const setConsent = (consent) => {
        const consentMode = {
            'functionality_storage': consent.necessary ? 'granted' : 'denied',
            'security_storage': consent.necessary ? 'granted' : 'denied',
            'ad_storage': consent.marketing ? 'granted' : 'denied',
            'analytics_storage': consent.analytics ? 'granted' : 'denied',
            'personalization_storage': consent.preferences ? 'granted' : 'denied',
        };
        
        // Update Google Tag Manager consent
        if (window.gtag) {
            window.gtag('consent', 'update', consentMode);
        }
        
        localStorage.setItem('consentMode', JSON.stringify(consentMode));
    };

    const handleAcceptAll = () => {
        setConsent({
            necessary: true,
            analytics: true,
            preferences: true,
            marketing: true
        });
        setShowBanner(false);
    };

    const handleAcceptSome = () => {
        setConsent({
            necessary: true,
            analytics: document.getElementById('consent-analytics').checked,
            preferences: document.getElementById('consent-preferences').checked,
            marketing: document.getElementById('consent-marketing').checked
        });
        setShowBanner(false);
    };

    const handleRejectAll = () => {
        setConsent({
            necessary: false,
            analytics: false,
            preferences: false,
            marketing: false
        });
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div id="cookie-consent-banner" className="cookie-consent-banner">
            <h3>Cookie settings</h3>
            <p>We use cookies to provide you with the best possible experience. They also allow us to analyze user behavior in order to constantly improve the website for you.</p>
            <button 
                id="btn-accept-all" 
                className="cookie-consent-button btn-success"
                onClick={handleAcceptAll}
            >
                Accept All
            </button>
            <button 
                id="btn-accept-some" 
                className="cookie-consent-button btn-outline"
                onClick={handleAcceptSome}
            >
                Accept Selection
            </button>
            <button 
                id="btn-reject-all" 
                className="cookie-consent-button btn-grayscale"
                onClick={handleRejectAll}
            >
                Reject All
            </button>
            <div className="cookie-consent-options">
                <label>
                    <input 
                        id="consent-necessary" 
                        type="checkbox" 
                        value="Necessary" 
                        defaultChecked 
                        disabled 
                    />
                    Necessary
                </label>
                <label>
                    <input 
                        id="consent-analytics" 
                        type="checkbox" 
                        value="Analytics" 
                        defaultChecked 
                    />
                    Analytics
                </label>
                <label>
                    <input 
                        id="consent-preferences" 
                        type="checkbox" 
                        value="Preferences" 
                        defaultChecked 
                    />
                    Preferences
                </label>
                <label>
                    <input id="consent-marketing" 
                        type="checkbox" 
                        value="Marketing" 
                    />
                    Marketing
                </label>
            </div>
        </div>
    );
};

export default Cookies;