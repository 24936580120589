import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { styled, alpha } from '@mui/material/styles';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import "../assets/css/course.css";
import { useEffect, useState } from 'react';
import { API_URL } from '../Config';

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.groupTransition}`]: {
      marginLeft: 22,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    ...theme.applyStyles('light', {
      color: theme.palette.grey[800],
    }),
  }));


function LevelOneIco(props) {
    return (
      <SvgIcon
        className="close"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        fill="none"
        style={{ width: 20, height: 20 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */}
        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 24 24" fill="none" stroke="#616161" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M5 12h13M12 5l7 7-7 7"/></svg>
      </SvgIcon>
    );
  }

function LevelTwoIco(props) {
    return (
      <SvgIcon
        className="close"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        fill="none"
        style={{ width: 10, height: 10 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */}
        <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 24 24" fill="none" stroke="#616161" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
      </SvgIcon>
    );
  }


const Course = ({ nItems }) => {  
  const [courseStructure, setCourseStructure] = useState([]);
//<a href={'/course/' + module.id + '/' + chapter.page_url_en} className='ignore-underline-course' key={'chapter' + chapterIndex}>
    useEffect(() => {
      const fetchCourseStructure = async () => {
        const cachedData = localStorage.getItem('cachedCourseStructure');
        const cachedTimestamp = localStorage.getItem('cachedCourseStructureTimestamp');
        const currentTime = new Date().getTime();

        if (cachedData && cachedTimestamp && (currentTime - parseInt(cachedTimestamp) < 360000)) {
            setCourseStructure(JSON.parse(cachedData));
        } else {
            const response = await fetch(API_URL + '/modules');
            const data = await response.json();
            console.log(data);
            setCourseStructure(data);
            localStorage.setItem('cachedCourseStructure', JSON.stringify(data));
            localStorage.setItem('cachedCourseStructureTimestamp', currentTime.toString());
        }
      };
      fetchCourseStructure();
    }, []);

return (
    <div className="container">
      { nItems === undefined && <div className='card-header'>
            <h1 className='mt-3'>Interactive SQL Course</h1>
            <p className='mt-3'>A comprehensive SQL course designed to forever change your perspective on SQL. Together we will journey to understand how this language works and acquire all the necessary skills for its effective application at work.</p>
        </div> }
        
        
        <div className='card-course bg-light rounded'>
            {courseStructure.length > 0 && courseStructure.slice(0, nItems).map((module, index) => (
                <div className="row" key={'module' + index}>
                    <div className="col-md-6">
                        <div className="module">
                            <h2>{module.title_en}</h2>
                            <p>{module.description_en}</p>
                        </div>
                    </div>
                    <div className="col-md-6 mb-5">
                        <SimpleTreeView 
                            defaultExpandedItems={module.chapters.map((_, chapterIndex) => chapterIndex.toString())}
                            disableSelection
                            slots={{
                                expandIcon: LevelOneIco,
                                collapseIcon: LevelOneIco,
                                endIcon: LevelTwoIco,
                        }}>
                            {module.chapters.map((chapter, chapterIndex) => (
                                <CustomTreeItem itemId={chapterIndex.toString()} label={<div onClick={event => window.location.href = '/course/' + module.id + '/' + chapter.id + '/' + chapter.id }>{chapter.title_en}{JSON.parse(localStorage.getItem('readChapters'))?.includes(chapter.id) ? ' ✅' : ''}</div>} style={{marginBottom: '10px'}} key={`chapter-${chapterIndex}`}>
                                    
                                    {chapter.children && chapter.children.map((child, childIndex) => (
                                        <CustomTreeItem itemId={`${chapterIndex}-${childIndex}`} label={<div onClick={event => window.location.href = '/course/' + module.id + '/' + chapter.id + '/' + child.id }>{child.title_en}{JSON.parse(localStorage.getItem('readChapters'))?.includes(child.id) ? ' ✅' : ''}</div>}  key={`child-${chapterIndex}-${childIndex}`}>
                                        </CustomTreeItem>
                                    ))}
                                </CustomTreeItem>
                            ))}
                        </SimpleTreeView>
                        
                    </div>
                </div>
            ))}
        </div>
    </div>
);
}

export default Course;
