import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid'; // for generating unique IDs

const SessionContext = createContext();

export function SessionProvider({ children }) {
    // Initialize sessionId from storage or create new one
    const [sessionId, setSessionId] = useState(() => {
        // Try to get existing sessionId from storage
        const storedSessionId = localStorage.getItem('sessionId');
        // If no existing sessionId, create new one
        return storedSessionId || uuidv4();
    });

    // Persist sessionId to storage whenever it changes
    useEffect(() => {
        localStorage.setItem('sessionId', sessionId);
    }, [sessionId]);

    return (
        <SessionContext.Provider value={sessionId}>
            {children}
        </SessionContext.Provider>
    );
}

// Custom hook to use session
export function useSession() {
    const context = useContext(SessionContext);
    if (!context) {
      throw new Error('useSession must be used within a SessionProvider');
    }
    return context;
  }