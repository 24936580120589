import React from 'react';
import { useEffect, useState } from 'react';
import '../assets/css/aitutor.css'; 
import { API_URL } from '../Config';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw"


const AITutor = () => {
    const [content, setContent] = useState("");

    const fetchContent = async () => {
        try {
            const response = await fetch(API_URL + "/content/fetch-markdown-common?url=https://raw.githubusercontent.com/ai-learning-space/sql-expert/refs/heads/main/common/AI Tutor.md");
            if (!response.ok) {
                setContent("Network response was not ok");
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setContent(data.content);
            console.log(data);
        } catch (error) {
            setContent("There has been a problem with your fetch operation");
            console.error('There has been a problem with your fetch operation:', error);
        }
    };

    useEffect(() => {
        fetchContent();
    }, []);
    
    return (
        <div className='container custom-container'>
            <ReactMarkdown 
                children={content}  
                rehypePlugins={[rehypeRaw]} 
                remarkPlugins={[remarkGfm]} 
            />
            <div class="arrow-container">
            <div class="curved-arrow">
                
            <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" 
   width="1600px" height="1600px" viewBox="0 0 270.353 270.353" style={{transform: 'scale(5.5)'}}>

  <path d="M368.432,166.578c-23.256-19.584-42.84-42.84-63.648-64.872c-11.016-11.628-28.151-34.884-46.512-36.108
    c-1.224-1.836-3.06-3.06-7.344-2.448c-2.448,0.612-4.284,2.448-4.896,4.896c-3.672,14.076-3.06,28.764-3.06,43.452
    c-53.856-7.344-116.893,19.584-159.732,48.348C36.115,191.057-0.605,243.078,0.007,301.217c0,2.448,1.836,3.672,3.672,4.284
    c1.836,1.836,4.284,2.448,6.732,1.224c70.992-50.184,145.044-94.247,235.009-78.947h0.611c-5.508,17.748-6.119,37.943-6.119,56.304
    c0,5.508,4.896,7.344,8.567,6.732c1.836,2.447,6.12,4.283,9.18,1.224c40.393-33.66,84.456-71.604,111.385-117.503
    C370.88,172.698,370.88,168.414,368.432,166.578z M348.235,183.102c-10.403,15.3-23.868,28.764-37.332,42.228
    c-18.359,18.36-39.168,34.272-56.916,53.244c0-16.524-0.611-33.048,0.612-50.185c0-1.836-0.612-3.06-1.836-3.672
    c1.836-3.672,1.224-8.567-3.672-9.792c-88.741-18.972-170.749,23.256-239.292,77.112c9.18-53.856,41.004-94.248,86.904-124.848
    s93.637-36.72,146.269-42.84v0.612c-0.612,4.896,7.344,5.508,8.568,1.224c0-1.224,0.611-1.836,0.611-3.06
    c3.672-1.836,4.896-6.732,1.836-8.568c1.836-9.792,2.448-19.584,3.672-29.376c0-1.836,0.612-4.896,1.225-7.956
    c7.956,10.404,21.42,19.584,30.6,28.764c17.748,18.36,34.272,37.944,52.632,56.304
    C351.907,170.862,356.191,170.862,348.235,183.102z"/>

</svg>
            </div>
            </div>
        </div>
    );
};

export default AITutor;
