import Split from 'react-split'
import EditorComponent from './Editor';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import '../assets/css/trainerTasks.css'
import "../assets/css/resizable.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { API_URL } from '../Config';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  };
  
const Sandbox = () => {
    const [db, setDb] = React.useState('');
    const [dbList, setDbList] = useState({});
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    
    useEffect(() => {
      const fetchDatabases = async () => {
        
        const response = await fetch(API_URL + '/dbs');
        const data = await response.json();
        const databases = data.databases;
        setDbList(databases);
      };
      fetchDatabases();
    }, []);

    const handleChange = (event) => {
        setDb(event.target.value);
    };

    const runHandler = (e) => {
      localStorage.setItem('sandbox', e.target.value);

      const generateData = async () => {
        const response = await fetch(API_URL + '/dbs/' + db + '/execute', {
          method: 'POST',
          headers: {
            "accept": "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({"query": e.target.value})
        });
        setPage(0);
        const data = await response.json();
        
        setAlertSeverity('info');
        setAlertContent('BAANG! 😁🎓');
        setIsSnackbarOpen(true);
        
        if (data.rows) {
          setRows(data.rows);
        
          const firstRow = data.rows[0];
          const columnsList = Object.keys(firstRow).map(key => ({ id: key, label: key, minWidth: 100 }));
          console.log(columnsList);
          setColumns(columnsList);
        } else {
          setRows([{ error: data.detail }]);
          setColumns([{ id: 'error', label: 'Error', minWidth: 100 }]);
        }
      };
      generateData();
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  return (
    <div>
      <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={isSnackbarOpen}
              autoHideDuration={1500}
              onClose={() => setIsSnackbarOpen(false)}
            >
              <Alert severity={alertSeverity} sx={{ boxShadow: 4 }}> 
                {alertContent}
              </Alert>
            </Snackbar>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Тут лежит схема БД
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          А тут её описание
        </Typography>
      </Box>
    </Modal>
    <Split
        className="wrap"
        sizes={[35, 65]}
        minSize={200}
        expandToMin={false}
        gutterSize={5}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
    >  
      <div className="container bg-light">
        <h5 className='mt-2'>Select Database</h5>
        <FormControl fullWidth>
        <InputLabel id="db-select-label">Database</InputLabel>
        <Select
          labelId="db-select-label"
          id="db-select"
          value={db}
          label="DB"
          onChange={handleChange}
        >
          {Object.keys(dbList).map((key) => (
            <MenuItem value={key}>{dbList[key]}</MenuItem>
          ))}
        </Select>
      </FormControl>
        <hr></hr>
        {db !== '' && (
          <iframe width="100%" height="400px" allowtransparency="true" allowfullscreen="true" scrolling="no" title="Embedded DrawSQL IFrame" frameborder="0" src={"https://drawsql.app/teams/sql-expert-1/diagrams/" + db.replaceAll("_", "-") + "/embed"}></iframe>
        )}

    </div>
      <div className='bg-white'>
          <EditorComponent isSandbox={true} runHandler={runHandler} cachedContent={localStorage.getItem('sandbox') || ''}/>
          <hr className='hr hr-blurry mt-0'></hr>
          <div>
            <h5 className="result mt-2" style={{marginLeft: '5px'}}>Query result</h5>
            <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                      {columns.map((column) => (
                          <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          >
                          {column.label}
                          </TableCell>
                      ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                          return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                  <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                              );
                              })}
                          </TableRow>
                          );
                      })}
                  </TableBody>
                  </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
              />
        </div>
      </div>
    </Split>
  </div>
  );
}

export default Sandbox;
