import React from 'react';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import "../assets/css/course.css";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw"
import { flushSync } from 'react-dom'
import { API_URL } from '../Config';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { emojiBlast } from "emoji-blast";
import ChapterBreadcrumbs from './ChapterBreadcrumbs';


const CourseChapter = () => {
    let { moduleId, parentChapterId, chapterId } = useParams();
    const [chapterContent, setChapterContent] = useState('');
    const [isRead, setIsRead] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hierarchy, setHierarchy] = useState([]);
    const [prevNext, setPrevNext] = useState({});
    const [error, setError] = useState(null);
    const [alertContent, setAlertContent] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');    
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const copyToClipboard = async (text) => {

        try {
            navigator.clipboard.writeText(text).then(() => {
                console.log('Text copied to clipboard' + text);
                setAlertContent('Copied to clipboard 📋');
                setAlertSeverity('success');
                setIsSnackbarOpen(true);
            }).catch((err) => {
                console.error('Could not copy text: ', err);
            });
            return text;
        } catch (error) {
            console.error(error);
            return "No data found";
        }
    };

    function initializeCodeBlocks() {
        flushSync(() => {
            if (window.highlightAll) {
                window.highlightAll();
            }
            window.copyToClipboard = copyToClipboard;
            const codeBlocks = document.querySelectorAll('pre');
            codeBlocks.forEach(block => {
                // Check if the element doesn't have click listeners
                if (!block.onclick && !block._clickListener) {
                    const clickHandler = () => {
                        copyToClipboard(block.textContent);
                    };
                    block.addEventListener('click', clickHandler);
                    // Store the listener reference
                    block._clickListener = clickHandler;
                }
            });
        });
    }

    async function getHierarchy() {
        let elements = [];
        if (parentChapterId === chapterId) {
            elements = [
                moduleId,
                chapterId
            ]
        } else {
            elements = [
                moduleId,
                parentChapterId,
                chapterId
            ]
        }

        let hierarchy = [];
        for (let i = 0; i < elements.length; i++) {
            let response = await fetch(API_URL + '/modules/elements/' + elements[i]);
            let data = await response.json();
            hierarchy.push(data);
        }
        setHierarchy(hierarchy);
    }

    async function getPrevNext() {
        let response = await fetch(API_URL + '/modules/adjacent/' + chapterId);
        let data = await response.json();
        setPrevNext(data);
    }

    async function markAsRead() {
        let readChapters = JSON.parse(localStorage.getItem('readChapters')) || [];
        if (!readChapters.includes(chapterId)) {
            readChapters.push(chapterId);
            localStorage.setItem('readChapters', JSON.stringify(readChapters));
            setIsRead(true);
            setAlertContent('You are the GOAT! 🐐');
            setAlertSeverity('success');
            setIsSnackbarOpen(true);
            setTimeout(() => {
                emojiBlast();
            }, 500);
        }
    }

    async function openAdjacent(isNext) {
        let adjacent = {};
        if (isNext) {
            adjacent = prevNext.next;
        } else {
            adjacent = prevNext.previous;
        }

        let adjacentHierarchy = [];
        if (adjacent.parent_id) {
            adjacentHierarchy = [adjacent.module_id, adjacent.parent_id, adjacent.id];
        } else {
            adjacentHierarchy = [adjacent.module_id, adjacent.id, adjacent.id];
        }

        console.log("Adjacent hierarchy", adjacentHierarchy);

        if (adjacent.length === 2) {
            window.location.href = '/course/' + adjacentHierarchy[0] + '/' + adjacentHierarchy[1] + '/' + adjacentHierarchy[1]
        } else {
            window.location.href = '/course/' + adjacentHierarchy[0] + '/' + adjacentHierarchy[1] + '/' + adjacentHierarchy[2]
        }
    }


    async function getStaticProps() {
        try {
            setIsLoading(true);
            const response = await fetch(
                API_URL + "/content/fetch-markdown?chapter_id=" + chapterId,
                { cache: "no-store" },
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setChapterContent(data.content);
            setError(null);

            initializeCodeBlocks();

            return {
                props: {
                    blockMap: data
                }
            };
        } catch (error) {
            setError(error.message);
            setChapterContent('');
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        document.title = 'SQL Expert | ' + chapterId.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        // TODO: get a proper title
        const readChapters = JSON.parse(localStorage.getItem('readChapters')) || [];
        setIsRead(readChapters.includes(chapterId));
    }, []);

    useEffect(() => {
        getStaticProps();
        initializeCodeBlocks();
    }, [chapterId]); // Changed dependency to chapterId

    useEffect(() => {
        if (chapterContent) {
            const images = document.querySelectorAll('img');
            images.forEach(img => {
                img.classList.add('img-fluid', 'd-block', 'mx-auto');
            });
            initializeCodeBlocks();
        }
        getHierarchy();
        getPrevNext();
    }, [chapterContent]);

    if (isLoading) {
        return (
            <div className="container custom-container">
                <p>Loading...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container custom-container">
                <h2>Error</h2>
                <p>Failed to load content: {error}</p>
            </div>
        );
    }

    if (!chapterContent) {
        return (
            <div className="container custom-container">
                <h2>Not Found</h2>
                <p>The requested chapter content could not be found.</p>
            </div>
        );
    }

    return (
        <div className='container custom-container'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpen}
                autoHideDuration={1500}
                onClose={() => setIsSnackbarOpen(false)}
            >
                <Alert severity={alertSeverity} sx={{ boxShadow: 4 }}>
                    {alertContent}
                </Alert>
            </Snackbar>
            <ChapterBreadcrumbs hierarchy={hierarchy} />
            {isRead && (
                <p style={{ marginTop: '1vh' }}>You read this chapter ✅</p>
            )}
            <ReactMarkdown
                children={chapterContent}
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
            />
            <div className="d-flex justify-content-between mt-3 mb-3">
                <button className="btn btn-light shadow-sm border" onClick={() => openAdjacent(false)}>⬅️ Previous Chapter</button>
                {!isRead && (
                    <button className="btn btn-light shadow-sm border" onClick={() => markAsRead()}>Mark as read ✅</button>
                )}
                <button className="btn btn-light shadow-sm border" onClick={() => openAdjacent(true)}>Next Chapter ➡️</button>
            </div>
        </div>
    );
}

export default CourseChapter;
