import React from 'react';
import { Box, Typography, Button, Avatar, AvatarGroup, Card, CardContent, Grid } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const CourseCard = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div className='main-course-card'>
      <Box display="flex" alignItems="flex-end" justifyContent="left" mt={2}>
      <Typography variant="p" component="div">
          powered by
        </Typography>
        <Box component="img" alt="Course Logo" src="https://www.southeastlinuxfest.org/wp-content/uploads/2016/04/postgresql-logo.png" sx={{ height: '5vh', ml: '1vh' }} />
      </Box>
    <h1 style={{marginTop: '3vh'}}>Learn SQL for Data Analysis and Engineering with AI</h1>
    <Grid item xs={12}>
        <Typography variant="subtitle1">
        Get immediate feedback from AI tutor and prepare for your next job interview
        </Typography>
    </Grid>

    <Grid item xs={12}>
        <AvatarGroup max={4} >
        <Avatar alt="Instructor 3" src="/static/vladislav-raskoshinskii.jpg"/>
        <Avatar alt="Instructor 4" src="/static/aleksandr-perevalov.jpg"/>
        </AvatarGroup>
    </Grid>

    <Grid item xs={12}>
      <Button variant="contained" color="primary" size="large" onClick={() => window.location.href = '/course'}>
        Start learning now
      </Button>
    </Grid>

    <div style={{marginTop: '3vh' }}>
    <Box sx={{ bgcolor: '#f5f5f5', position: 'relative', top: '3vh' }}>
      <Card sx={{ mx: 'auto', boxShadow: 3, borderRadius: 3 }}>
        <CardContent>
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={isMobile ? 4 : 3}>
                  <Typography component="div" variant="body1">
                    <b>7 modules</b>
                  </Typography>
                  <Typography variant="body2">Gain insight into a topic and learn the fundamentals or advanced material.</Typography>
                </Grid>
                <Grid item xs={isMobile ? 4 : 3}>
                  <Typography component="div" variant="body1">
                    <b>4.8 ★</b>
                  </Typography>
                  <Typography variant="body2">(Based on our students' reviews)</Typography>
                </Grid>
                <Grid item xs={isMobile ? 4 : 3}>
                  <Typography component="div" variant="body1">
                    <b>Beginner or Intermediate level</b>
                  </Typography>
                  <Typography variant="body2">Recommended experience</Typography>
                </Grid>
                {!isMobile && (
                  <Grid item xs={3}>
                    <Typography component="div" variant="body1">
                      <b>95% ★</b>
                    </Typography>
                    <Typography variant="body2">Most learners liked this course</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
    </div>
    </div>
  );
};

export default CourseCard;