import * as React from 'react';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { API_URL } from '../Config';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}


export default function ChapterBreadcrumbs({ hierarchy }) {
    // hierarchy is a list of dicts with the length 2 or 3
    console.log(hierarchy)
    let elements = [];
  return (
    <div role="presentation">
        {hierarchy.length === 2 && (
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    color="inherit"
                    href={'/module/' + hierarchy[0].id}
                >
                    {hierarchy[0].title_en}
                </Link>
                <Link
                    underline="hover"
                    aria-current="page"
                    color="text.primary"
                    href={'/course/' + hierarchy[0].id + '/' + hierarchy[1].id + '/' + hierarchy[1].id}
                >
                    {hierarchy[1].title_en}
                </Link>
            </ Breadcrumbs>
        )}
        {hierarchy.length === 3 && (
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    color="inherit"
                    href={'/module/' + hierarchy[0].id}
                >
                    {hierarchy[0].title_en}
                </Link>
                <Link
                    underline="hover"
                    color="inherit"
                    href={'/course/' + hierarchy[0].id + '/' + hierarchy[1].id + '/' + hierarchy[1].id}
                >
                    {hierarchy[1].title_en}
                </Link>
                <Link
                    underline="hover"
                    aria-current="page"
                    color="text.primary"
                    href={'/course/' + hierarchy[0].id + '/' + hierarchy[1].id + '/' + hierarchy[2].id}
                >
                    {hierarchy[2].title_en}
                </Link>
                </Breadcrumbs>
        )}
      
    </div>
  );
}
